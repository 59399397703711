import {
  Description,
  DialogPanel,
  DialogTitle,
  Field,
  Input,
  Label,
} from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import { ModalView } from "./SecurityModal";

const SecurityDigitCode = ({
  setView,
}: {
  setView: React.Dispatch<React.SetStateAction<ModalView>>;
}) => {
  return (
    <DialogPanel className="max-w-lg space-y-4 border bg-white p-12 rounded-md">
      <DialogTitle className="font-medium text-xl">
        Enter Confirmation Code
      </DialogTitle>
      <Description className={""}>
        Input the 6-digit code we just sent to +2348125467223
      </Description>
      <p>
        Didn’t receive a code?{" "}
        <span className="underline underline-offset-4 text-brandOrange-100">
          Resend code
        </span>
      </p>
      <div className="w-full max-w-md px-4">
        <Field className={"flex flex-col"}>
          <Label className="text-left font-medium ">Enter 6-digit code</Label>
          <Input
            type="number"
            max={6}
            min={6}
            className={clsx(
              "mt-3 block w-full rounded-lg border h-12 py-1.5 px-3 ",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
            )}
          />
        </Field>
      </div>

      <div className="flex gap-4 mt-8">
        <button className="border w-1/2 border-brandOrange-100 text-brandOrange-100 rounded-md font-semibold" onClick={() => setView(ModalView.PhoneNumber)}>
          Back
        </button>
        <button className="border w-1/2 border-brandOrange-100 rounded-md h-12 bg-brandOrange-100 text-white font-semibold" onClick={() => setView(ModalView.BackupCode)}>
          Next
        </button>
      </div>
    </DialogPanel>
  );
};

export default SecurityDigitCode;
