import React from "react";
import { formatDate } from "../utils";
import { IoStarSharp } from "react-icons/io5";

const UserFeedback = ({ feedback }: { feedback: any }) => {
  return (
    <div className="w-full border-b mt-4 border-brandBlack-200 pb-4">
      <div>
        <p>{feedback.review}</p>
      </div>
      <div className="flex justify-between items-center mt-2">
        <span className="flex gap-x-4">
          <p>{feedback.customerName}</p> |{" "}
          <p>{formatDate(new Date(feedback.dateReviewed))}</p>
        </span>
        <span>
          <div className=" flex justify-center">
            <IoStarSharp
              size={20}
              color={feedback.rating >= 1 ? "#F89521" : "#CDCDCD"}
            />
            <IoStarSharp
              size={20}
              color={feedback.rating >= 2 ? "#F89521" : "#CDCDCD"}
            />
            <IoStarSharp
              size={20}
              color={feedback.rating >= 3 ? "#F89521" : "#CDCDCD"}
            />
            <IoStarSharp
              size={20}
              color={feedback.rating >= 4 ? "#F89521" : "#CDCDCD"}
            />
            <IoStarSharp
              size={20}
              color={feedback.rating >= 5 ? "#F89521" : "#CDCDCD"}
            />
          </div>
        </span>
      </div>
    </div>
  );
};

export default UserFeedback;
