import { Switch } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiFetch } from "../../services";

const Preference = () => {
  type Links = "Overview" | "Security" | "Preference";

  const [active, setActive] = useState<Links>("Preference");
  const [news, setNews] = useState(false);
  const [preferences, setPreferences] = useState()



  const getRecentDesigns = async () => {
    const { data, isLoaded } = await apiFetch(
      "/api/CreatorPreference/Get",
      "GET"
    );
    console.log({ data });
    if (isLoaded && data.statusCode == 200) {
      console.log(data);
      setPreferences(data.data);
    }
  };

  useEffect(() => {
    getRecentDesigns();
  }, [])
  
  

  const desktopPreferences = [
    {
      title: "News and Updates",
      description: "Get news about product features and updates",
      enabled: true,
    },
    {
      title: "Enable Notification Badge",
      description:
        "Show a red badge on the bell icon when you have a new notification",
      enabled: true,
    },
    {
      title: "Tips and Guides",
      description: "Tips and guides on getting the best on Fotologue",
      enabled: false,
    },
    {
      title: "Payments",
      description: "When you have been paid or need payment updates ",
      enabled: true,
    },
    {
      title: "Comments and Ratings",
      description: "Comments and ratings on your designs",
      enabled: false,
    },
  ];

  const emailPreferences = [
    {
      title: "Communication Emails",
      description: "Receive emails about feature updates, improvements, etc",
      enabled: false,
    },
    {
      title: "Announcements and Updates",
      description: "Receive emails about feature updates, improvements, etc",
      enabled: true,
    },
  ];

  const soundPreferences = [
    {
      title: "All Notification Sounds",
      description: "Receive emails about feature updates, improvements, etc",
      enabled: false,
    },
  ];

  return (
    <div className="pb-10">
      <div className="rounded bg-white p-6">
        <p className="mb-4 text-lg font-semibold">Manage Account</p>
        <div
          className={`border-b w-full h-14 mb-5 flex justify-start gap-x-6 items-center`}
        >
          <Link to={"/profile"}>
            <div
              className={`relative h-14 flex cursor-pointer items-center ${
                active == "Overview"
                  ? "text-brandOrange-100"
                  : "text-brandBlack-200"
              }`}
            >
              Overview
              {active == "Overview" && (
                <span className="w-full absolute left-0 bottom-0 bg-brandOrange-100 rounded-md h-1"></span>
              )}
            </div>
          </Link>
          <Link to={"/profile/security"}>
            <div
              className={`relative h-14 flex cursor-pointer items-center ${
                active == "Security"
                  ? "text-brandOrange-100"
                  : "text-brandBlack-200"
              }`}
            >
              Security
              {active == "Security" && (
                <span className="w-full absolute left-0 bottom-0 bg-brandOrange-100 rounded-md h-1"></span>
              )}
            </div>
          </Link>
          <Link to={"/profile/preference"}>
            <div
              className={`relative h-14 flex cursor-pointer items-center ${
                active == "Preference"
                  ? "text-brandOrange-100"
                  : "text-brandBlack-200"
              }`}
            >
              Preference
              {active == "Preference" && (
                <span className="w-full absolute left-0 bottom-0 bg-brandOrange-100 rounded-md h-1"></span>
              )}
            </div>
          </Link>
        </div>
        <div className="mt-4">
          <h2 className="font-medium">Desktop Notifications</h2>
          {desktopPreferences.map(
            (preference: {
              title: string;
              description: string;
              enabled: boolean;
            }) => (
              <div className="flex justify-between mt-6">
                <div>
                  <h2 className="text-base font-medium">{preference.title}</h2>
                  <p className="text-sm text-brandBlack-200">
                    {preference.description}
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  {preference.enabled ? (
                    <label htmlFor="">Enabled</label>
                  ) : (
                    <label htmlFor="">Disabled</label>
                  )}
                  <Switch
                    checked={preference.enabled}
                    onChange={setNews}
                    className="group ml-8 inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-brandOrange-100"
                  >
                    <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                  </Switch>
                </div>
              </div>
            )
          )}
          {/* <div className="flex justify-between mt-6">
            <div className="flex items-center justify-center">
              <label htmlFor="">Enabled</label>
              <Switch
                checked={news}
                onChange={setNews}
                className="group ml-8 inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-brandOrange-100"
              >
                <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
              </Switch>
            </div>
          </div> */}
        </div>
        <div className="mt-8">
          <h2 className="font-medium">Email Notifications</h2>
          {emailPreferences.map(
            (preference: {
              title: string;
              description: string;
              enabled: boolean;
            }) => (
              <div className="flex justify-between mt-6">
                <div>
                  <h2 className="text-base font-medium">{preference.title}</h2>
                  <p className="text-sm text-brandBlack-200">
                    {preference.description}
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  {preference.enabled ? (
                    <label htmlFor="">Enabled</label>
                  ) : (
                    <label htmlFor="">Disabled</label>
                  )}
                  <Switch
                    checked={preference.enabled}
                    onChange={setNews}
                    className="group ml-8 inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-brandOrange-100"
                  >
                    <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                  </Switch>
                </div>
              </div>
            )
          )}
        </div>
        <div className="mt-8">
          <h2 className="font-medium">Sound Notifications</h2>
          {soundPreferences.map(
            (preference: {
              title: string;
              description: string;
              enabled: boolean;
            }) => (
              <div className="flex justify-between mt-6">
                <div>
                  <h2 className="text-base font-medium">{preference.title}</h2>
                  <p className="text-sm text-brandBlack-200">
                    {preference.description}
                  </p>
                </div>
                <div className="flex items-center justify-center">
                  {preference.enabled ? (
                    <label htmlFor="">Enabled</label>
                  ) : (
                    <label htmlFor="">Disabled</label>
                  )}
                  <Switch
                    checked={preference.enabled}
                    onChange={setNews}
                    className="group ml-8 inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-brandOrange-100"
                  >
                    <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                  </Switch>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Preference;
