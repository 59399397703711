import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Field,
  Input,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import clsx from "clsx";
import React, { useEffect } from "react";
import { FiX } from "react-icons/fi";
import { apiFetch } from "../services";

const EarningsModal = ({
  toggleShowEarnings,
  setShowActivity,
  earning,
}: {
  toggleShowEarnings: any;
  setShowActivity: any;
  earning: any;
}) => {
  useEffect(() => {
    const getEarnings = async () => {
      try {
        const { data, isLoaded } = await apiFetch(``, "GET");
        if (data.statusCode == 200 && isLoaded) {
          console.log(data);
        }
      } catch (error) {}
    };

    getEarnings();
  }, []);

  return (
    <>
      <Transition appear show={true}>
        <Dialog
          as="div"
          className="relative z-10 focus:outline-none"
          onClose={toggleShowEarnings}
          __demoMode
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 transform-[scale(95%)]"
                enterTo="opacity-100 transform-[scale(100%)]"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 transform-[scale(100%)]"
                leaveTo="opacity-0 transform-[scale(95%)]"
              >
                <DialogPanel className="rounded-lg bg-white h-[794px] w-[778px] p-6 backdrop-blur-2xl relative">
                  <FiX
                    className="absolute top-4 right-3 text-gray-400"
                    size={22}
                    onClick={() => toggleShowEarnings()}
                  />
                  <DialogTitle as="h3" className="text-xl font-medium ">
                    {earning}
                  </DialogTitle>

                  <div className="mt-4">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Date, Time
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Usage
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Views
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Earnings
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              20 Feb , 2014 12pm
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Sign in
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              192.158.1.38
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Lagos, NG
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              20 Feb , 2014 12pm
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Sign in
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              192.158.1.38
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Lagos, NG
                            </td>
                          </tr>
                          <tr>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              20 Feb , 2014 12pm
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Sign in
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              192.158.1.38
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              Lagos, NG
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EarningsModal;
