import React, { useEffect, useState } from "react";
import { FiTrash2, FiSearch, FiFilter, FiEye, FiEdit2 } from "react-icons/fi";
import { FaArrowTrendUp } from "react-icons/fa6";
import {
  designStatusBadge,
  designStatusText,
  formatDate,
  numberWithCommas,
} from "../../utils";
import { apiFetch } from "../../services";
import { Link } from "react-router-dom";

const Design = () => {
  const designs = [
    {
      name: "Happy Birtday Cap'n",
      category: "Birthday Card",
      usage: 23,
      view: 50,
      amount: 5200,
      date: "15 Feb, 2023",
      status: "Pending",
    },
    {
      name: "Happy Birtday Tony",
      category: "Anniversary Card",
      amount: 1200,
      usage: 13,
      view: 38,
      date: "12 Feb, 2023",
      status: "Approved",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>();
  const [pageSize, setPageSize] = useState<number>();
  const [totalCount, setTotalCount] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [design, setDesign] = useState<[]>();
  useEffect(() => {
    const getDesigns = async () => {
      setLoading(true);
      const { data, isLoaded } = await apiFetch(
        `/api/CreatorDesignTemplate/Search?Page=${currentPage}&PageSize=10`,
        "GET"
      );
      if (isLoaded && data?.statusCode == 200) {
        console.log(data);
        setDesign(data.data.data);
        setPage(data.data.page);
        setPageSize(data.data.pageSize);
        setTotalCount(data.data.totalCount);
        setTotalPages(data.data.totalPages);
        setLoading(false);
      }
    };

    getDesigns();
  }, [currentPage]);
  return (
    <div className="h-screen bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="font-medium">
          My Designs
          <span className="text-sm ml-1 font-normal text-darkBlue-200">
            ({`${design && design.length}`} items)
          </span>
        </p>
        <div className="flex gap-x-4">
          <div className="h-8 w-8 bg-brandOrange-300 rounded-full flex justify-center items-center">
            <FiSearch color="#F89521" size={"20"} />
          </div>

          <div className="h-8 w-8 bg-brandOrange-300 rounded-full flex justify-center items-center">
            <FiFilter color="#F89521" size={"20"} />
          </div>
          <div className="h-9 w-9 bg-brandOrange-300 rounded-full flex justify-center items-center">
            <FaArrowTrendUp color="#F89521" size={"20"} />
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root px-4">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden  ring-opacity-5 sm:rounded-lg">
              <table className="w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Design Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Usage
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      View
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Date Modified
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {design && design.length > 0
                    ? design.map((design: any) => (
                        <tr key={design.name}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 text-brandBlack-300">
                            {design.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 text-brandBlack-300">
                            {design.category}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-brandBlack-300">
                            {design.usage}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-brandBlack-300">
                            {design.view}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                            <div
                              className={` flex items-center justify-center h-8 w-[70px] rounded-md text-xs ${designStatusText(
                                design.status
                              )} ${designStatusBadge(design.status)}`}
                            >
                              {design.status}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-brandBlack-300">
                            {formatDate(new Date(design.dateModified))}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                            <div className="flex gap-x-2">
                              <Link to={`/design/${design.id}`}>
                                <div className="bg-[#FDEFEB] h-7 w-7 flex justify-center items-center rounded-full cursor-pointer">
                                  <FiEye size={16} color="#F89521" />
                                </div>
                              </Link>
                              <div className="bg-[#FDEFEB] h-7 w-7 flex justify-center items-center rounded-full cursor-pointer">
                                <FiEdit2 size={16} color="#F89521" />
                              </div>
                              <div className="bg-[#FDEFEB] h-7 w-7 flex justify-center items-center rounded-full cursor-pointer">
                                <FiTrash2 size={16} color="#F89521" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    : "No Orders"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
