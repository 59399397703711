import { Description, DialogPanel, DialogTitle } from '@headlessui/react';
import React from 'react'
import { ModalView } from './SecurityModal';

const SecuritySuccessMessage = ({
  setView,
}: {
  setView: React.Dispatch<React.SetStateAction<ModalView>>;
}) => {
  return (
    <DialogPanel className="max-w-lg space-y-4 border bg-white p-12 rounded-md">
      <DialogTitle className="font-medium text-xl">Setup Complete</DialogTitle>
      <Description className={"text-lg"}>
        From now on, when you sign in to your account, you will need to enter a
        security code sent to you via text message
      </Description>

      <div className="flex gap-4 mt-8">
        <div></div>
        <button
          className="border w-1/2 border-brandOrange-100 rounded-md h-12 bg-brandOrange-100 text-white font-semibold"
          onClick={() => setView(ModalView.PhoneNumber)}
        >
          Next
        </button>
      </div>
    </DialogPanel>
  );
};

export default SecuritySuccessMessage