import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from "@headlessui/react";
import Cookies from "js-cookie";
import React, { Fragment, useState } from "react";

const SignOutModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const signOut = () => {
    Cookies.remove("accessToken");
    Cookies.remove("storeFrontId");
    Cookies.remove("TenantId");
    window.location.href = "/login"
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="max-w-lg space-y-4 border bg-white p-12 rounded-md">
                  <DialogTitle className="font-medium text-xl">
                    Sign Out
                  </DialogTitle>
                  <Description className="text-brandBlack-200">
                    Are you sure you want to sign out of your Fotologue account?
                  </Description>
                  <div className="flex gap-4 mt-8">
                    <button className="border w-1/2 border-brandOrange-100 text-brandOrange-100 rounded-md font-semibold">
                      Cancel
                    </button>
                    <button
                      className="border w-1/2 border-brandOrange-100 rounded-md h-12 bg-brandOrange-100 text-white font-semibold"
                      onClick={signOut}
                    >
                      Yes, i'm sure
                    </button>
                  </div>
                </DialogPanel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SignOutModal;
