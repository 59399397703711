import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
  Field,
  Input,
  Label,
  Transition,
} from "@headlessui/react";
import clsx from "clsx";
import { Fragment, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import SecurityOptionsModal from "./SecurityOptionsModal";
import SecurityPhoneModal from "./SecurityPhoneModal";
import SecurityDigitCode from "./SecurityDigitCode";
import SecurityCodes from "./SecurityBackupCodes";
import SecuritySuccessMessage from "./SecuritySuccessMessage";

export enum ModalView {
  AuthOption,
  PhoneNumber,
  DigitCode,
  BackupCode,
  Success,
}

export function SecurityModal({
  view,
  setView,
}: {
  view: ModalView;
  setView: React.Dispatch<React.SetStateAction<ModalView>>;
}) {
  let [isOpen, setIsOpen] = useState(true);

  const [selected, setSelected] = useState("");

  const codes = [
    {
      first: "TJEVU3",
      second: "PTQU34",
    },
    {
      first: "TJEVU3",
      second: "PTQU34",
    },
    {
      first: "TJEVU3",
      second: "PTQU34",
    },
    {
      first: "UDOD2J",
      second: "1892E4",
    },
    {
      first: "W8921E",
      second: "PTQU34",
    },
    {
      first: "TJEVU3",
      second: "PTQU34",
    },
  ];

  const getView = (view: ModalView) => {
    switch (view) {
      case ModalView.AuthOption:
        return <SecurityOptionsModal setView={setView} />;
      case ModalView.PhoneNumber:
        return <SecurityPhoneModal setView={setView} />;
      case ModalView.DigitCode:
        return <SecurityDigitCode setView={setView} />;
      case ModalView.BackupCode:
        return <SecurityCodes setView={setView} />;
      case ModalView.Success:
        return <SecuritySuccessMessage setView={setView} />;

      default:
        return <SecurityOptionsModal setView={setView} />;
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as="div">
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as="div"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <>{getView(view)}</>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
