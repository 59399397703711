import { Description, Field, Input, Label, Textarea } from "@headlessui/react";
import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import SignOutModal from "../../component/SignOutModal";

const Profile = () => {
  type Links = "Overview" | "Security" | "Preference";

  const [active, setActive] = useState<Links>("Overview");

  return (
    <div className="pb-10">
      <SignOutModal />
      <div className="rounded bg-white p-6">
        <p className="mb-4 text-lg font-semibold">Manage Account</p>
        <div
          className={`border-b w-full h-14 mb-5 flex justify-start gap-x-6 items-center`}
        >
          <Link to={"/profile"}>
            <div
              className={`relative h-14 flex cursor-pointer items-center ${
                active == "Overview"
                  ? "text-brandOrange-100"
                  : "text-brandBlack-200"
              }`}
            >
              Overview
              {active == "Overview" && (
                <span className="w-full absolute left-0 bottom-0 bg-brandOrange-100 rounded-md h-1"></span>
              )}
            </div>
          </Link>
          <Link to={"/profile/security"}>
            <div
              className={`relative h-14 flex cursor-pointer items-center ${
                active == "Security"
                  ? "text-brandOrange-100"
                  : "text-brandBlack-200"
              }`}
            >
              Security
              {active == "Security" && (
                <span className="w-full absolute left-0 bottom-0 bg-brandOrange-100 rounded-md h-1"></span>
              )}
            </div>
          </Link>
          <Link to={"/profile/preference"}>
            <div
              className={`relative h-14 flex cursor-pointer items-center ${
                active == "Preference"
                  ? "text-brandOrange-100"
                  : "text-brandBlack-200"
              }`}
            >
              Preference
              {active == "Preference" && (
                <span className="w-full absolute left-0 bottom-0 bg-brandOrange-100 rounded-md h-1"></span>
              )}
            </div>
          </Link>
        </div>
        <div className="w-[730px] mx-auto flex flex-col justify-center text-center gap-y-4">
          <div className="flex flex-col items-center gap-y-4 mx-auto">
            <p>Profile Picture</p>
            <div className="w-40 h-40 rounded-full border"></div>
            <div className="flex gap-x-5">
              <button className="text-brandOrange-100 border-brandOrange-100 rounded py-2 w-[150px] border">
                Change Photo
              </button>
              <button className="text-brandBlack-400 border-brandBlack-400 rounded py-2 w-[150px] border">
                Remove Photo
              </button>
            </div>
          </div>
          <p className="mt-20 pb-4 border-b text-left">Basic Information</p>
          <div className="w-full">
            <Field className={"w-full items-start text-left"}>
              <Label className="text-sm/6 font-medium">First Name:</Label>
              <Input
                className={clsx(
                  "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
              />
            </Field>
          </div>
          <div className="w-full">
            <Field className={"w-full items-start text-left"}>
              <Label className="text-sm/6 font-medium">Last Name:</Label>
              <Input
                className={clsx(
                  "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
              />
            </Field>
          </div>
          <div className="w-full">
            <Field>
              <Label className="text-left font-medium">About Me</Label>
              <Description className="text-sm/6 text-left">
                150 characters max.
              </Description>
              <Textarea
                className={clsx(
                  "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
                rows={8}
              />
            </Field>
          </div>

          <div className="w-full grid grid-cols-2 gap-x-4">
            <Field className={"col-span-1 items-start text-left"}>
              <Label className="text-sm/6 font-medium">Email Address:</Label>
              <Input
                className={clsx(
                  "mt-2 block w-full border-brandBlack-600 bg-brandBlack-600 rounded border py-1.5 px-3 text-sm/6 h-12",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
              />
            </Field>
            <Field className={"col-span-1 items-start text-left"}>
              <Label className="text-sm/6 font-medium">Phone Number:</Label>
              <Input
                className={clsx(
                  "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
              />
            </Field>
          </div>
          <div className="w-full grid grid-cols-2 gap-x-4">
            <Field className={"col-span-1 items-start text-left"}>
              <Label className="text-sm/6 font-medium">Address:</Label>
              <Input
                className={clsx(
                  "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
              />
            </Field>
            <Field className={"col-span-1 items-start text-left"}>
              <Label className="text-sm/6 font-medium">City:</Label>
              <Input
                className={clsx(
                  "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
              />
            </Field>
          </div>
          <div className="w-full grid grid-cols-2 gap-x-4">
            <Field className={"col-span-1 items-start text-left"}>
              <Label className="text-sm/6 font-medium">
                Postal Code (Optional):
              </Label>
              <Input
                className={clsx(
                  "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
              />
            </Field>
            <Field className={"col-span-1 items-start text-left"}>
              <Label className="text-sm/6 font-medium">State/Province:</Label>
              <Input
                className={clsx(
                  "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
              />
            </Field>
          </div>
          <div className="w-full grid grid-cols-2 gap-x-4">
            <Field className={"col-span-1 items-start text-left"}>
              <Label className="text-sm/6 font-medium">Country:</Label>
              <Input
                className={clsx(
                  "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                  "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
                )}
              />
            </Field>
          </div>
          <div className="flex justify-end">
            <button className="bg-brandOrange-100 rounded text-white py-3 w-[230px]">
              Update details
            </button>
          </div>
          <div className="flex justify-center mt-8">
            <button className="bg-transparent rounded text-brandOrange-100 border border-brandOrange-100 py-3 w-[230px]">
              Change Password
            </button>
          </div>
        </div>
      </div>
      <div className="rounded bg-white p-6 mt-10">
        <p className="pb-4 border-b font-medium text-lg">Payment Information</p>
        <div className="w-[730px] mx-auto mt-10 grid grid-cols-2 gap-x-4">
          <Field className={"col-span-1 items-start text-left"}>
            <Label className="text-sm/6 font-medium">Account Number:</Label>
            <Input
              className={clsx(
                "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
              )}
            />
          </Field>
          <Field className={"col-span-1 items-start text-left"}>
            <Label className="text-sm/6 font-medium"> Account Name:</Label>
            <Input
              className={clsx(
                "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
              )}
            />
          </Field>
        </div>
        <div className="w-[730px] mx-auto mt-10 grid grid-cols-2 gap-x-4">
          <Field className={"col-span-1 items-start text-left"}>
            <Label className="text-sm/6 font-medium">Bank Name:</Label>
            <Input
              className={clsx(
                "mt-2 block w-full border-brandBlack-400 rounded border py-1.5 px-3 text-sm/6 h-12",
                "w-full focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-brandBlack-400"
              )}
            />
          </Field>
        </div>
        <div className="w-full my-8 flex justify-center">
          <button className="bg-brandOrange-100 rounded text-white py-3 w-[230px]">
            Update details
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
