import {
  Description,
  DialogPanel,
  DialogTitle,
  Field,
  Input,
  Label,
} from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { ModalView } from "./SecurityModal";

const SecurityPhoneModal = ({
  setView,
}: {
  setView: React.Dispatch<React.SetStateAction<ModalView>>;
}) => {
  return (
    <DialogPanel className="max-w-lg space-y-4 border bg-white p-12 rounded-md">
      <DialogTitle className="font-medium text-xl">
        Setup Phone Number
      </DialogTitle>
      <Description className={""}>
        This will be the device we send verification codes each time you log
        into Fotologue
      </Description>
      <div className="w-full max-w-md px-4">
        <Field className={"flex flex-col"}>
          <Label className="text-left font-medium ">Phone Number</Label>
          <Input
            type="number"
            className={clsx(
              "mt-3 block w-full rounded-lg border h-12 py-1.5 px-3 ",
              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
            )}
          />
        </Field>
      </div>

      <div className="flex gap-4 mt-8">
        <button className="border w-1/2 border-brandOrange-100 text-brandOrange-100 rounded-md font-semibold" onClick={() => setView(ModalView.AuthOption)}>
          Cancel
        </button>
        <button className="border w-1/2 border-brandOrange-100 rounded-md h-12 bg-brandOrange-100 text-white font-semibold" onClick={() => setView(ModalView.DigitCode)}>
          Next
        </button>
      </div>
    </DialogPanel>
  );
};

export default SecurityPhoneModal;
