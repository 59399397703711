import React, { useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import UserFeedback from "../../component/UserFeedback";
import { apiFetch } from "../../services";
import { useParams } from "react-router-dom";

const DesignDetails = () => {
  const [details, setDetails] = useState<any>();
  const { id } = useParams();
  useEffect(() => {
    const getFeedbacks = async () => {
      const { data, isLoaded } = await apiFetch(
        `/api/CreatorDesignTemplate/GetTemplateFeedbacks?templateId=${id}`,
        "GET"
      );
      if (data.statusCode == 200 && isLoaded) {
        setDetails(data.data);
        console.log("details +>", data);
      }
    };

    getFeedbacks();
  }, []);

  return (
    <div className="h-screen bg-white p-6">
      <div className="">
        <p className="font-medium text-brandOrange-100 flex gap-x-3 items-center">
          <FaChevronLeft /> Back
        </p>
        <h2 className="mt-4">Design Details</h2>
      </div>
      <div className="grid grid-cols-5 gap-x-8 mt-4">
        <div className="col-span-2 bg-green-200 h-auto">
          <img src="https://picsum.photos/800/1000" alt="" />
        </div>
        <div className="col-span-3 h-auto ">
          <h2 className="text-2xl font-medium">{details?.details.name}</h2>
          <div className="gap-x-2 flex">
            <p className="text-brandBlack-200">Category:</p>
            <p className="">{details?.details.name}</p>
          </div>
          <div className="gap-x-2 flex">
            <p className="text-brandBlack-200">Tag:</p>
            <p className="">{details?.details.tags}</p>
          </div>
          <div className="gap-x-2 flex">
            <p className="text-brandBlack-200">Status:</p>
            <p className="">{details?.details.statusName}</p>
          </div>
          <div className="gap-x-2 flex">
            <p className="text-brandBlack-200">Pages:</p>
            <p className="">Left, Right, Front, Back</p>
          </div>
          <div className="gap-x-2 flex">
            <p className="text-brandBlack-200">Description:</p>
            <p className="">---</p>
          </div>
          <div className="gap-x-2 flex">
            <p className="text-brandBlack-200">Number of uses:</p>
            <p className="">{details?.details.reviewCount}</p>
          </div>
          <div className="gap-x-2 flex">
            <p className="text-brandBlack-200">Total Earning:</p>
            <p className="">---</p>
          </div>
          <div className="gap-x-2 flex">
            <p className="text-brandBlack-200">Average Feedback:</p>
            <p className="">{details?.details.statusName}</p>
          </div>
          <div>
            {details?.feedbacks.map((feedback: any) => (
              <UserFeedback feedback={feedback} />
            ))}
          </div>
          <div className="flex justify-end mt-3">
            <p className="text-brandOrange-100 flex gap-x-2 items-center">
              View All Feedbacks
              <FaChevronRight />{" "}
            </p>
          </div>
          <div className="flex justify-start mt-3">
            <div className="gap-x-2 flex">
              <p className="text-brandBlack-200">Number of uses: :</p>
              <p className="">Lorem ipsum dolor sit.</p>
            </div>
          </div>
          <div className="flex justify-end mt-3 gap-x-4 text-sm">
            <button className="border px-3 py-2 rounded border-brandRed-200 text-brandRed-200">
              {" "}
              Delete Design
            </button>

            <button className="border px-3 py-2 rounded border-brandOrange-100 text-brandOrange-100">
              Edit Design
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignDetails;
