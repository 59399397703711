import { Switch } from "@headlessui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ModalView, SecurityModal } from "../../component/securityModals/SecurityModal";

const Security = () => {
  type Links = "Overview" | "Security" | "Preference";

  const [active, setActive] = useState<Links>("Security");
  const [enabled, setEnabled] = useState(false);
  const [view, setView] = useState<ModalView>(ModalView.AuthOption)
  return (
    <>
      <SecurityModal view={view} setView={setView} />
      <div className="pb-10">
        <div className="rounded bg-white p-6">
          <p className="mb-4 text-lg font-semibold">Manage Account</p>
          <div
            className={`border-b w-full h-14 mb-5 flex justify-start gap-x-6 items-center`}
          >
            <Link to={"/profile"}>
              <div
                className={`relative h-14 flex cursor-pointer items-center ${
                  active == "Overview"
                    ? "text-brandOrange-100"
                    : "text-brandBlack-200"
                }`}
              >
                Overview
                {active == "Overview" && (
                  <span className="w-full absolute left-0 bottom-0 bg-brandOrange-100 rounded-md h-1"></span>
                )}
              </div>
            </Link>
            <Link to={"/profile/security"}>
              <div
                className={`relative h-14 flex cursor-pointer items-center ${
                  active == "Security"
                    ? "text-brandOrange-100"
                    : "text-brandBlack-200"
                }`}
              >
                Security
                {active == "Security" && (
                  <span className="w-full absolute left-0 bottom-0 bg-brandOrange-100 rounded-md h-1"></span>
                )}
              </div>
            </Link>
            <Link to={"/profile/preference"}>
              <div
                className={`relative h-14 flex cursor-pointer items-center ${
                  active == "Preference"
                    ? "text-brandOrange-100"
                    : "text-brandBlack-200"
                }`}
              >
                Preference
                {active == "Preference" && (
                  <span className="w-full absolute left-0 bottom-0 bg-brandOrange-100 rounded-md h-1"></span>
                )}
              </div>
            </Link>
          </div>
          <div className="mt-4">
            <p>Two-Factor Authentication (2FA)</p>
            <div className="mt-3 grid grid-cols-4">
              <div className="col-span-3">
                <p>
                  Two-factor authentication adds an extra layer of security for
                  your account. Whenever you log in to your account, after
                  entering your username and password, you will be asked for a
                  second authentication code that was sent to your mobile phone
                  via text or a free mobile app.{" "}
                  <span className="underline text-brandBlue-200 underline-offset-4 cursor-pointer">
                    Learn more
                  </span>
                </p>
              </div>
              <div className="flex items-center justify-center">
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600"
                >
                  <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                </Switch>
              </div>
            </div>
            <div>
              <button className="bg-brandOrange-100 h-12 rounded-md text-white px-4 mt-6">
                Setup Two-Factor Authentication
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
