import { Description, DialogPanel, DialogTitle } from '@headlessui/react';
import React, { useState } from 'react'
import { ModalView } from './SecurityModal';

const SecurityCodes = ({
  setView,
}: {
  setView: React.Dispatch<React.SetStateAction<ModalView>>;
}) => {
  let [isOpen, setIsOpen] = useState(true);

  const [selected, setSelected] = useState("");

  const codes = [
    {
      first: "TJEVU3",
      second: "PTQU34",
    },
    {
      first: "TJEVU3",
      second: "PTQU34",
    },
    {
      first: "TJEVU3",
      second: "PTQU34",
    },
    {
      first: "UDOD2J",
      second: "1892E4",
    },
    {
      first: "W8921E",
      second: "PTQU34",
    },
    {
      first: "TJEVU3",
      second: "PTQU34",
    },
  ];
  return (
    <DialogPanel className="max-w-lg space-y-4 border bg-white p-12 rounded-md">
      <DialogTitle className="font-medium text-xl">
        Backup Recovery Codes
      </DialogTitle>
      <Description className={""}>
        With two-factor authentication enabled for your account, you will need
        these backup up codes if you ever lose your device. We recommend you
        keep them in a safe place.
      </Description>
      <p>
        Didn’t receive a code?{" "}
        <span className="underline underline-offset-4 text-brandOrange-100">
          Resend code
        </span>
      </p>

      <div className="flex gap-4 mt-8">
        <button
          className="border w-1/2 border-brandOrange-100 text-brandOrange-100 rounded-md font-semibold"
          onClick={() => setIsOpen(false)}
        >
          Back
        </button>
        <button
          className="border w-1/2 border-brandOrange-100 rounded-md h-12 bg-brandOrange-100 text-white font-semibold"
          onClick={() => setView(ModalView.Success)}
        >
          Next
        </button>
      </div>
    </DialogPanel>
  );
};

export default SecurityCodes