import React from "react";
import Logo from "../assets/FOTOLOGUE-LOGO-BLACK 1.png";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineColorLens } from "react-icons/md";
import { FiBell, FiPackage, FiTrendingUp, FiUser } from "react-icons/fi";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
export const navItems = [
 
  {
    name: "Dashboard",
    icon: <MdOutlineDashboard size={20} />,
    route: "/dashboard",
  },
  {
    name: "Design Templates",
    icon: <MdOutlineColorLens size={20} />,
    route: "/design",
  },
  {
    name: "Earnings",
    icon: <FiPackage size={20} />,
    route: "/earnings",
  },
  {
    name: "Analytics",
    icon: <FiTrendingUp size={20} />,
    route: "/analytics",
  },
  {
    name: "Wallet",
    icon: <FiUser size={20} />,
    route: "/wallet",
  },
  {
    name: "Notifications",
    icon: <FiBell size={20} />,
    route: "/notifications",
  },
  {
    name: "Resources",
    icon: <FaRegQuestionCircle size={20} />,
    route: "/resources",
  },
];
const SideNav = () => {
  const loc = useLocation();
  // const link = loc.pathname()
  console.log(navItems[1].route.split("/")[1]);
  navItems.map((item) => loc.pathname.includes(item.route));

  return (
    <div
      className={`bg-[#1E1F1F] w-[272px] h-full fixed left-0 top-0 z-[99999]
      
      `}
    >
      <div className="p-5 border-b">
        <img src={Logo} alt="" />
      </div>
      <div className="p-5 flex flex-col items-center gap-y-2 text-white">
        {navItems.map((item: any) => (
          <Link to={item.route}>
            <div
              className={`w-[232px] rounded items-center justify-start text-sm font-medium ${
                loc.pathname.includes(item.route.split("/")[1])
                  ? "bg-brandBlack-500"
                  : ""
              } cursor-pointer h-12 px-4 hover:bg-brandBlack-500 ${
                item.route === "/" ? "hidden" : "flex"
              }`}
            >
              {item.icon}
              <p className="ml-3">{item.name}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SideNav;
