import React, { useEffect, useState } from "react";
import Dropdown, { IValue } from "../../component/DropdownComponent";
import { FiTrendingUp } from "react-icons/fi";
import TotalRevenueBarChart from "../../component/charts/TotalRevenueBarChart";
import { FaChevronRight } from "react-icons/fa";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
} from "recharts";
import RatedDesigns from "../../component/RatedDesigns";
import { apiFetch } from "../../services";
import { numberWithCommas } from "../../utils";

const data = [
  {
    name: "Mon",
    rev: 4000,
  },
  {
    name: "Tue",
    rev: 3000,
  },
  {
    name: "Wed",
    rev: 2000,
  },
  {
    name: "Thur",
    rev: 2780,
  },
  {
    name: "Fri",
    rev: 1890,
  },
  {
    name: "Sat",
    rev: 2390,
  },
  {
    name: "Sun",
    rev: 3490,
  },
];

const graphData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    // amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    // amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    // amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    // amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    // amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    // amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    // amt: 2100,
  },
];

export interface IGraph {
  name: string;
  usage: string;
  views: string;
}

export interface IGraphEarnings {
  name: string;
  usage: string;
}

const Analytics = () => {
  const intervals = [
    { id: 1, name: "Week" },
    { id: 2, name: "Month" },
    { id: 3, name: "Year" },
  ];
  const [revenueInterval, setRevenueInterval] = useState<IValue>(intervals[0]);
  const [performingDesigns, setPerformingDesigns] = useState([]);
  const [designEarnings, setDesignEarnings] = useState([]);
  const [loading, setLoading] = useState(false);
  let designGraphInfo: IGraph[] = [];
  let EarningGraphInfo: IGraphEarnings[] = [];

  useEffect(() => {
    const getDesigns = async () => {
      const { data, isLoaded } = await apiFetch(
        "/api/CreatorDesignTemplate/GetDesignerPerformingTemplate",
        "GET"
      );
      if (data.statusCode == 200 && isLoaded) {
        console.log(data);
        setLoading(false);
        setPerformingDesigns(data.data);
      }
    };

    const getEarnings = async () => {
      const { data, isLoaded } = await apiFetch(
        "/api/CreatorDesignTemplate/GetDesignerTemplateEarnings",
        "GET"
      );
      if (data.statusCode == 200 && isLoaded) {
        console.log(data);
        setLoading(false);
        setDesignEarnings(data.data);
      }
    };
    const getEngagements = async () => {
      const { data, isLoaded } = await apiFetch(
        `/api/CreatorDesignTemplate/GetDesignerCustomerEngagement?type=3090`,
        "GET"
      );
      if (data.statusCode == 200 && isLoaded) {
        console.log(data);
        setLoading(false);
        setDesignEarnings(data.data);
      }
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        console.log(element);
        const graphObj = {
          name: element.name,
          usage: element.usage,
        };
        EarningGraphInfo.push(graphObj);
        console.log({ designGraphInfo });
      }
      console.log({ designGraphInfo });
    };

    getEarnings();
    getDesigns();
    getEngagements();
  }, []);

  return (
    <div className=" bg-green-300h-screen flex flex-col gap-y-8">
      <div className="h-[654px] grid bg-white grid-cols-3 gap-x-6">
        <div className="col-span-2 ">
          <div className="grid-col-2 bg-white p-4">
            <p>Total Revenue</p>
            <div className="w-full flex justify-between items-center mt-2">
              <p className="font-black text-2xl">N100,000</p>
              <div className="w-[68px] h-8 rounded-lg relative bg-[#FDEFEB]">
                <Dropdown
                  selected={revenueInterval}
                  setSelected={setRevenueInterval}
                  values={intervals}
                />
              </div>
            </div>
            <div className="">
              <BarChart
                width={960}
                height={500}
                data={designGraphInfo}
                // margin={{
                //   top: 20,
                //   right: 30,
                //   left: 20,
                //   bottom: 5,
                // }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip />
                <Legend />
                <Bar
                  yAxisId="left"
                  dataKey="usage"
                  fill="#8884d8"
                  //   layout="horizontal"
                />
                <Bar
                  yAxisId="right"
                  dataKey="views"
                  fill="#82ca9d"
                  //   layout="horizontal"
                />
              </BarChart>
            </div>
          </div>
        </div>

        <div className="grid-col-1 bg-white  pt-4 px-8 flex flex-col items-center mt-10">
          <div className="border-b">
            <RatedDesigns
              design={performingDesigns[0]}
              title={"Top performing design"}
            />
          </div>
          <div>
            <RatedDesigns
              design={performingDesigns[1]}
              title={"Least performing design"}
            />
          </div>
        </div>
      </div>
      <div className="h-[489px] grid grid-cols-3 bg-white gap-x-6">
        <div className="col-span-2  border px-4 ">
          <div className="grid-col-2 bg-white p-4">
            <p>Total earnings</p>
            <div className="w-full flex justify-between items-center mt-2">
              <p className="font-black text-2xl">N100,000</p>
              <div className="w-[68px] h-8 rounded-lg relative bg-[#FDEFEB]">
                <Dropdown
                  selected={revenueInterval}
                  setSelected={setRevenueInterval}
                  values={intervals}
                />
              </div>
            </div>
            <div className="flex items-center gap-x-2 my-2">
              <span>
                <FiTrendingUp color="#39B54A" />
              </span>
              <p className="text-[#39B54A] text-sm">2.4</p>
            </div>
            <BarChart width={900} height={400} data={EarningGraphInfo}>
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Usage" fill="#8884d8" />
            </BarChart>
            <div className="flex justify-end text-brandOrange-100 text-sm items-center gap-x-1">
              View graph <FaChevronRight size={10} />
            </div>
          </div>
        </div>
        <div className="col-span-1 mt-10 flex flex-col items-center">
          <h1>Top Earning Designs</h1>
          <div className="flex flex-col mt-2 gap-y-3">
            {designEarnings.map((earning: any) => (
              <div className="flex flex-col mt-3">
                <p className="mb-2 text-brandOrange-100 underline underline-offset-8">
                  {earning.name}
                </p>
                <p className="font-bold text-xl">
                  ₦ {numberWithCommas(`${earning.amount}`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
