import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "./component/Layout";
import Login from "./pages/Login";
import LandingPage from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
import Design from "./pages/design/Design";
import Earnings from "./pages/earnings";
import Notifications from "./pages/notification";
import Resources from "./pages/resources";
import Profile from "./pages/profile";
import Analytics from "./pages/analytics";
import Security from "./pages/profile/Security";
import Preference from "./pages/profile/Preference";
import DesignDetails from "./pages/design/Details";

export const API_BASE_URL = "https://bluemoonapi-uat.bts.com.ng";
function App() {
  const location = useLocation();
  // const navigate = useNavigate();
  // console.log(location);

  const noLayoutRoutes = ["/login", "/", "/register", "/forgot-password"];

  const pageNoLayout = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <LandingPage />,
    },
  ];

  const pageLayout = [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/design",
      element: <Design />,
    },
    {
      path: "/design/:id",
      element: <DesignDetails />,
    },
    {
      path: "/earnings",
      element: <Earnings />,
    },
    {
      path: "/notifications",
      element: <Notifications />,
    },
    {
      path: "/resources",
      element: <Resources />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/profile/security",
      element: <Security />,
    },
    {
      path: "/profile/preference",
      element: <Preference />,
    },
    {
      path: "/analytics",
      element: <Analytics />,
    },
  ];

  return (
    <>
      {noLayoutRoutes.includes(location.pathname) ? (
        <Routes>
          {pageNoLayout.map((page) => (
            <Route path={page.path} element={page.element} />
          ))}
        </Routes>
      ) : (
        <Layout>
          <Routes>
            {pageLayout.map((page) => (
              <Route path={page.path} element={page.element} />
            ))}
          </Routes>
        </Layout>
      )}
    </>
  );
}

export default App;
