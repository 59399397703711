import React, { useEffect, useState } from "react";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FiEye, FiFilter, FiSearch } from "react-icons/fi";
import { numberWithCommas } from "../../utils";
import { apiFetch } from "../../services";
import EarningsModal from "../../component/EarningsModal";

const Earnings = () => {
  const [ShowModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>();
  const [pageSize, setPageSize] = useState<number>();
  const [totalCount, setTotalCount] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [earnings, setEarnings] = useState<[]>();
  useEffect(() => {
    const getEarnings = async () => {
      setLoading(true);
      const { data, isLoaded } = await apiFetch(
        `/api/CreatorEarning/Search?Page=${currentPage}&PageSize=10`,
        "GET"
      );
      if (isLoaded && data?.statusCode == 200) {
        console.log(data);
        setEarnings(data.data.data);
        setPage(data.data.page);
        setPageSize(data.data.pageSize);
        setTotalCount(data.data.totalCount);
        setTotalPages(data.data.totalPages);
        setLoading(false);
      }
    };

    getEarnings();
  }, [currentPage]);

  const designs = [
    {
      name: "Happy Birtday Cap'n",
      category: "Birthday Card",
      usage: 23,
      view: 50,
      amount: 5200,
      date: "15 Feb, 2023",
      status: "Pending",
    },
    {
      name: "Happy Birtday Tony",
      category: "Anniversary Card",
      amount: 1200,
      usage: 13,
      view: 38,
      date: "12 Feb, 2023",
      status: "Approved",
    },
  ];
  return (
    <div className="h-screen bg-white p-6">
      {ShowModal && (
        <EarningsModal
          setShowActivity={setShowModal}
          toggleShowEarnings={() => setShowModal(!ShowModal)}
          earning={"Friends Piece"}
        />
      )}
      <div className="flex justify-between items-center">
        <div className="font-medium">
          <p className="font-medium">My Earnings</p>
          <div className="text-xl font-bold ">
            Total Earnings: {numberWithCommas("10000000")}
          </div>
        </div>
        <div className="flex gap-x-4">
          <div className="h-8 w-8 bg-brandOrange-300 rounded-full flex justify-center items-center">
            <FiSearch color="#F89521" size={"20"} />
          </div>

          <div className="h-8 w-8 bg-brandOrange-300 rounded-full flex justify-center items-center">
            <FiFilter color="#F89521" size={"20"} />
          </div>
          <div className="h-9 w-9 bg-brandOrange-300 rounded-full flex justify-center items-center">
            <FaArrowTrendUp color="#F89521" size={"20"} />
          </div>
        </div>
      </div>
      <div className="mt-8 flow-root px-4">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden  ring-opacity-5 sm:rounded-lg">
              <table className="w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Design Name
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 flex justify-center"
                    >
                      Usage
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 "
                    >
                      View
                    </th>

                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Total Earnings
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {earnings && earnings.length > 0
                    ? designs.map((design: any) => (
                        <tr>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 text-brandBlack-300 ">
                            {"--"}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-brandBlack-300  flex justify-center">
                            {design.usage}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-brandBlack-300 pl-8">
                            {design.view}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-brandBlack-300 pl-8">
                            {numberWithCommas(design.amount)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                            <div className="flex gap-x-2">
                              <div className="bg-[#FDEFEB] h-7 w-7 flex justify-center items-center rounded-full cursor-pointer" onClick={() => setShowModal(true)}>
                                <FiEye size={16} color="#F89521" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    : "No Orders"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earnings;
