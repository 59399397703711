import { Description, DialogPanel, DialogTitle } from "@headlessui/react";
import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { ModalView } from "./SecurityModal";

type Selection = "Text" | "App";

const SecurityOptionsModal = ({
  setView,
}: {
  setView: React.Dispatch<React.SetStateAction<ModalView>>;
}) => {
  const authOptions = [
    {
      title: "Text Message",
      description: "Receive a verification code vis SMS",
      id: "Text",
    },
    {
      title: "Authenticator App",
      description:
        "Use a mobile app like Google Authenticator to generate verification codes",
      id: "App",
    },
  ];
  const [selected, setSelected] = useState<Selection>("Text");

  return (
    <DialogPanel className="max-w-lg space-y-4 border bg-white p-12 rounded-md">
      <DialogTitle className="font-medium text-xl">
        Choose Authentication Method
      </DialogTitle>
      <Description className={"text-lg"}>
        How would you like us to send your security codes
      </Description>
      {authOptions.map(
        (option: { title: string; description: string; id: string }) => (
          <div
            className={`w-full rounded-lg border p-4 flex justify-between items-center gap-x-4 cursor-pointer ${
              selected == option.id
                ? "border-brandOrange-100"
                : "border-brandBlack-200"
            }`}
            onClick={() => setSelected(option.id as unknown as Selection)}
          >
            <div className="text-left">
              <h4 className="text-lg font-semibold">{option.title}</h4>
              <p className="text-sm">{option.description}</p>
            </div>
            {selected == option.id && (
              <span>
                <FaCheck className="text-brandOrange-100" />
              </span>
            )}
          </div>
        )
      )}
      <div className="flex gap-4 mt-8">
        <button className="border w-1/2 border-brandOrange-100 text-brandOrange-100 rounded-md font-semibold">
          Cancel
        </button>
        <button className="border w-1/2 border-brandOrange-100 rounded-md h-12 bg-brandOrange-100 text-white font-semibold" onClick={() => setView(ModalView.PhoneNumber)}>
          Next
        </button>
      </div>
    </DialogPanel>
  );
};

export default SecurityOptionsModal;
