import React, { useEffect } from "react";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import { redirect, useLocation, useNavigate } from "react-router-dom";

const Layout = ({ children }: { children: any }) => {
  const loc = useLocation();

  return (
    < div >
      <TopNav />
      <div
        className={`${
          loc.pathname === "/login" ? "" : "ml-[272px] mt-12 pt-8 px-4"
        } bg-slate-100 min-h-screen`}
      >
        {children}
      </div>
      <SideNav />
    </div>
  );
};

export default Layout;
